import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import entrustLogo from "../../assets/pictures/entrust-logo-footer.png";
import copyRightsLogo from "../../assets/pictures/entrust-copy-rights-1.png";

import "./Footer.css";
import "./Footer.mobile.css";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const { name, email, phoneNumber, message } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (_e) => {
    try {
      await axios.post("/api/emailForm", formData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="footer-background">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="footer-left-col">
              <h2>Let's get in touch!</h2>
              <form onSubmit={handleSubmit}>
                <input
                  className="footer-text-box"
                  type="text"
                  placeholder="Name*"
                  name="name"
                  required
                  onChange={onChange}
                  value={name}
                ></input>
                <input
                  className="footer-text-box"
                  type="text"
                  placeholder="Email*"
                  name="email"
                  required
                  onChange={onChange}
                  value={email}
                ></input>
                <input
                  className="footer-text-box"
                  type="text"
                  placeholder="Phone Number*"
                  name="phoneNumber"
                  required
                  onChange={onChange}
                  value={phoneNumber}
                ></input>
                
                <input
                className="footer-message-box"
                  type="text"
                  placeholder="Message"
                  name="message"
                  required
                  onChange={onChange}
                  value={message}
                ></input>
                <div className="footer-submit-button">
                  <button
                    type="submit"
                    class="btn btn-secondary btn-md submit-button"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="footer-right-col">
              <img src={entrustLogo} alt="logo"/>
              <h3>
                Contact us now!<br></br>
                +971 00 000 0000
              </h3>
              <Link to="/" className="footer-link">
                <p>HOME</p>
              </Link>
              <Link to="/aboutus" className="footer-link">
                <p>ABOUT US</p>
              </Link>
              <Link to="/services" className="footer-link">
                <p>SERVICES</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="row line-row">
          <div className="col">
            <div
              className="footer-horizontal-line"
            ></div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h6 className="footer-copy-rights"           >
              ENTRUST RE &#169; 2022 | Designed and developed by{" "}
              <img src={copyRightsLogo} alt="Copyrights logo"></img>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
